@import '~antd/dist/reset.css';

@media (max-width: 600px) {
  html {
    font-size: 10px;
  }
}

@media (min-width: 600px) and (max-width: 959px) {
  html {
    font-size: 12px;
  }
}

@media (min-width: 960px) and (max-width: 1279px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 1280px) {
  html {
    font-size: 16px;
  }
}

html, body {
  width: 100%;
  margin: 0;
  padding: 0;
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

body {
  background: transparent;
}

.wallet-adapter-button-trigger {
  background-color: #019cde !important;
}
